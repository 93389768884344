<template>
  <footer>
    <div class="content-wrapper">
      <div class="footer-wrapper">
        <div class="footer-sitemap">
          <div>
            <div class="footer-sitemap__title dimmed">Mapa strony</div>
            <ul>
              <li><a href="#home">O mnie</a></li>
              <li><a href="#inspirations">Inspiracje</a></li>
              <li><a href="#pricing">Cennik</a></li>
              <li><a href="#faq">FAQ</a></li>
            </ul>
          </div>
          <div>
            <div class="footer-sitemap__title dimmed">Kontakt</div>
            <ul>
              <li><a href="mailto:lessmess.biuro@gmail.com">Email</a></li>
              <li>
                <a href="https://www.facebook.com/lessmessjs">Facebook</a>
              </li>
              <li>
                <a href="https://www.instagram.com/lessmess.js/">Instagram</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-credit">
          <h3>LESS MESS ©</h3>
          <p class="dimmed pl-2">
            2022 | Made with 💙 by
            <a href="https://github.com/kondorsky" class="text-link"
              >kondorsky
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" src="./Footer.scss" />
