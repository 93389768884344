<template>
  <section id="home" class="landing-page">
    <div class="content-wrapper">
      <div class="landing-page__content section--fh">
        <span class="prefix mb-3">👋 Poznajmy się</span>
        <h1 class="mb-3">Hej, jestem Julia!</h1>
        <p class="mb-1 text-justify">
          Prowadzę Less Mess i zajmuję się declutteringiem oraz organizacją
          przestrzeni. Ale... czym właściwie jest decluttering? Z języka
          angielskiego „clutter” to po prostu rupiecie zaburzające przestrzeń, w
          której żyjemy. Decluttering oznacza zatem odgracanie. Jest to fizyczne
          i mentalne wsparcie klientów w pozbywaniu się zbędnych rzeczy.
        </p>
        <p class="mb-3 text-justify">
          Czy wiesz, że bałagan ma ogromny wpływ na naszą głowę? Panujący
          dookoła chaos wytrąca nas ze skupienia, stresuje, a także ogranicza
          naszą kreatywność! Nasz umysł jest zbombardowany nadmiernymi bodźcami
          - wzrokowymi, węchowymi, dotykowymi, przez co nie jesteśmy w stanie
          odpocząć.
        </p>
        <a class="flex-end mb-3" href="#practice"><button>Więcej</button></a>
      </div>
    </div>
    <div class="landing-page__bg"></div>
  </section>
  <section style="z-index: 2" id="practice">
    <div class="content-wrapper">
      <div class="card-box">
        <span class="prefix mb-4">Trochę informacji</span>
        <h2 class="mb-4">Jak to wygląda w&nbsp;praktyce?</h2>
        <Cardbox :items="practiceData" />
      </div>
    </div>
  </section>
  <section class="about-me" id="inspirations">
    <div class="about-me__bg"></div>
    <div class="content-wrapper">
      <div class="about-me__content section--fh">
        <span class="prefix mb-3">🧘‍♀️ Inspiracje</span>
        <h1 class="mb-3">Minimalizm</h1>
        <q class="mb-2 text-justify">
          Każdego dnia wzbudza się w nas fałszywą potrzebę posiadania czegoś, co
          jest nam zupełnie zbędne. Dla zrealizowania tych potrzeb jesteśmy w
          stanie pracować więcej, wpędzać się w długi, zaniedbywać relacje z
          bliskimi oraz tracić zdrowie, a&nbsp;w&nbsp;konsekwencji… trwonić
          nasze życie.
        </q>
        <figcaption>-Leo Babauta</figcaption>
      </div>
    </div>
  </section>
  <section class="pricing" id="pricing">
    <div class="content-wrapper">
      <div class="pricing__content">
        <h2 class="mb-4">Ile to kosztuje?</h2>
        <div class="pricing__cards mt-3">
          <div class="pricing__cards-item">
            <b class="mb-1">Konsultacja</b>
            <b>200zł</b>
            Czas trwania: 1.5-2h

            <b class="mt-3 mb-1 text-left"
              >Raport po konsultacji wraz z listą zakupową</b
            >
            <b>300zł</b>

            <span class="mt-3 mb-1 text-left">
              Każda z pozostałych usług jest wyceniana <br />indywidualnie w
              zależności od zakresu pracy
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="faq" id="faq">
    <div class="content-wrapper">
      <div class="faq__content">
        <div class="faq__content__body">
          <div class="prefix mb-4">🔍 Chcę dowiedzieć się więcej!</div>
          <h2 class="mb-4">FAQ</h2>
          <p class="mb-2">
            Poniżej znajdziesz odpowiedzi na najczęściej zadawane pytania
          </p>
          <Cardbox :items="faqData" />
        </div>
        <img width="400" height="600" src="@/assets/ordnung.webp" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
//components
import Cardbox from "@/components/Cardbox/Cardbox";

//config
import { FAQ_DATA, PRACTICE_DATA } from "./main.config";

export default {
  name: "Main",
  components: {
    Cardbox,
  },
  data() {
    return {
      faqData: FAQ_DATA,
      practiceData: PRACTICE_DATA,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./Main.scss" scoped lang="scss" />
