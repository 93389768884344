import { createApp } from "vue";
import App from "./App.vue";
import "./base.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8A9zkwR6GML2dAf8Go0i8g6FpBhI-97U",
  authDomain: "lessmess-b89e6.firebaseapp.com",
  projectId: "lessmess-b89e6",
  storageBucket: "lessmess-b89e6.appspot.com",
  messagingSenderId: "965513618584",
  appId: "1:965513618584:web:c7a78e918fddd7223bdced",
  measurementId: "G-1KMH1S00VQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

console.log(analytics);

createApp(App).mount("#app");

//check if fonts are loaded
document.fonts.ready.then(function () {
  document.body.classList.add("fonts-loaded");
});
