export const PRACTICE_DATA = [
  {
    title: "Konsultacja",
    content: `Spotkanie konsultacyjne jest zazwyczaj pierwszym krokiem do wspólnego odgracania&nbsp;lub organizacji przestrzeni. Umawiamy się na spotkanie na żywo lub online, podczas którego  poznaję Ciebie, Twoją rodzinę, domowników, Wasze zwyczaje. Omawiamy miejsca w Twoim domu, które stanowią dla Ciebie wyzwanie organizacyjne - wymagają odgracenia bądź lepszej funkcjonalności. Wyznaczamy Twoje cele i oczekiwania.
    Proponuję najlepsze rozwiązania dostosowane do Twoich potrzeb i wybieram metodę, zgodnie z którą będziesz lub będziemy działać. Po konsultacji możesz wdrożyć zmiany samodzielnie lub zdecydować się na dalszą współpracę. 
      <sub class="mt-1">*możliwość przygotowania raportu podsumowującego ze wskazówkami i linkami do poszczególnych organizerów</sub>`,
  },
  {
    title: "Decluttering",
    content: `Przechodzę razem z Tobą przez proces odgracania przestrzeni. Nie martw się! Nie oceniam, dlatego, jeśli właśnie ta obawa powstrzymuje Cię przed skorzystaniem z usługi, nie masz się czego bać! Pomagam Ci w podjęciu decyzji, które z rzeczy zachowasz, wyrzucisz, oddasz lub sprzedasz, wspierając i motywując Cię do działania na każdym kroku. Dopełnieniem wspólnej pracy jest zorganizowanie funkcjonalnych metod przechowywania.
      <br />
      Zazwyczaj sesja odgracenia jednego pomieszczenia trwa około 4-5 h.`,
  },
  {
    title: "Organizacja przestrzeni",
    content: `Jeżeli proces odgracania masz już za sobą, ale nadal poszukujesz najlepszych rozwiązań organizacyjnych, jesteś w dobrym miejscu. Pomogę Ci zorganizować Twoją przestrzeń w taki sposób, aby stała się funkcjonalna, estetyczna i dostosowana do Twoich indywidualnych potrzeb.`,
  },
  {
    title: "Projektowanie przestrzeni",
    content: `Korzystając z mebli modułowych IKEA, zaprojektuję dla Ciebie wymarzoną przestrzeń do przechowywania. Niezależnie od tego, czy potrzebujesz szafy, garderoby, pralni, spiżarni czy jakiegokolwiek innego miejsca do organizacji - stworzę dla Ciebie projekt dostosowany do Twoich potrzeb oraz metrażu. Wspólnie wybierzemy system mebli, który sprawdzi się u Ciebie najlepiej. 
    Jak cała usługa wygląda w praktyce? Wszystko może odbyć się nawet zdalnie! Jeżeli wybierzesz tę opcję, wyślę Ci do uzupełnienia formularz dotyczący twoich oczekiwań, możliwości przestrzennych oraz budżetu. Po wypełnieniu i odesłaniu otrzymasz ode mnie gotowy projekt 3D wraz z listą zakupów zawierającą wszystkie niezbędne elementy wyposażenia oraz akcesoria do przechowywania.
    Jeżeli wolisz spotkać się osobiście - nie ma problemu! Wówczas zrobię wszystkie pomiary na miejscu i wspólnie ustalimy cały koncept projektu.`,
  },
  {
    title: "Pomoc przy przeprowadzce",
    content: `Jeżeli w planach masz przeprowadzkę, ale boisz się, że cały proces pochłonie mnóstwo Twojego czasu i wyczerpie całą energię, ta usługa jest stworzona dla Ciebie! Zorganizuję  i będę koordynować cały proces pakowania, a następnie rozpakowywania rzeczy. Zaplanuję odpowiednie miejsca i sposoby przechowywania rzeczy w  nowym domu w taki sposób, aby w&nbsp;pełni wykorzystać funkcjonalność pomieszczeń. 
    <sub class="mt-1">*Możesz skorzystać tylko z części uslugi.</sub>`,
  },
  {
    title: "Segregowanie dokumentów",
    content: `Papierzyska... Są zmorą w wielu domach, bo zwyczajnie jest ich za dużo! W obawie, że przez przypadek wyrzucisz coś ważnego (a przecież nigdy nie wiadomo, czego będziesz potrzebować w&nbsp;przyszłości), trzymasz wszystko. No właśnie... nie! Nie musisz chomikować stosów dokumentów, gwarancji, instrukcji, opakowań, wyników badań, rachunków... Nauczę Cię, jak i co przechowywać w taki sposób, żeby wszystko było w jednym miejscu, a każdy z domowników od razu znalazł to, czego potrzebuje.`,
  },
  {
    title: "Decluttering i organizacja dla firm",
    content:
      "Próbujesz skupić się na wykonywaniu obowiązków, ale chaos panujący na Twoim biurku skutecznie Ci to utrudnia? Ile czasu tracisz codziennie na poszukiwanie jakiegoś potrzebnego dokumentu?  A może prowadzisz kawiarnię, ale zła organizacja miejsca pracy pochłania całą Twoją energię i czas, który mógłby być przeznaczony na doskonalenie Twojego biznesu? Jeżeli&nbsp;chcesz wynieść swoją firmę na wyższy poziom, pomogę Ci! Wspólnie zorganizujemy Twoje stare-nowe miejsce pracy!",
  },
];

export const FAQ_DATA = [
  {
    title: "Czy potrzebuję profesjonalnego organizatora?",
    content: `Jeżeli pomimo regularnego sprzątania czujesz, że ilość rzeczy Cię przytłacza, ale nie masz w sobie na tyle dużo motywacji, żeby rozstać się z niektórymi przedmiotami, to warto spróbować! Pomogę Ci dokonać pozytywnych zmian w Twoim życiu i odzyskać upragnioną przestrzeń. Będę Cię motywować i&nbsp;wspierać w podejmowaniu decyzji. Przejdziemy przez cały proces wspólnie. Z przyjemnością sporządzę listę drobnych zadań do wykonania, które omówimy podczas procesu (rzeczy do załatwienia, kupienia, naprawy lub zwrotu). Jeżeli potrzebujesz pomocy z organizacją rutyny dnia codziennego (obowiązków, planowania posiłków, systemów segregowania, organizacji przestrzeni dziecięcej) - również służę pomocą.`,
  },
  {
    title: "Dlaczego powinnam  zatrudnić właśnie Ciebie?",
    content:
      "Kocham to co robię i robię to z ogromną pasją! Odkąd pamiętam organizowałam wszystko w taki sposób, żeby ułatwić codzienne życie sobie i innym. Kiedy widzę, jak odgracanie odmienia życie moich klientów, utwierdzam się w przekonaniu, że to, co robię ma sens i jest to dla mnie ogromnym kopem do działania! Decluttering jest indywidualnym procesem, nierzadko związanym z emocjami. Każdy klient jest wyjątkowy i ma inne oczekiwania, dlatego zawsze dopasowuję metodę do klienta, a nie klienta do metody. Uważam, że nie ma jednego, uniwersalnego systemu pasującego do wszystkich.",
  },
  {
    title: "Czym odgracanie różni się od sprzątania?",
    content:
      "Bałagan bardzo często nie ma nic wspólnego z czystością. W danym pomieszczeniu może być czysto, ale ponieważ przestrzeń jest zagracona niepotrzebnymi rzeczami, tworzy się bałagan. Jeżeli nie wiesz, czy skorzystać z usługi, bo boisz się, że pozbędę się jakichś rzeczy bez konsultacji z Tobą - nic bardziej mylnego! To Ty podejmujesz każdą decyzję! Jestem po to, aby Cię wspierać i motywować do działania!",
  },
  {
    title: "Co z przedmiotami sentymentalnymi?",
    content:
      "Wspomnienia to bardzo ważna, intymna sfera naszego życia i nie ma konieczności pozbywania się wszystkiego. Warto jednak zastanowić się nad tym, czy naprawdę potrzebujemy danych przedmiotów. Jeżeli wyrazisz chęć, przejdziemy przez ten proces wspólnie, a ja pomogę Ci w podjęciu decyzji.",
  },
  {
    title: "Czy moja obecność podczas sesji declutteringu jest wymagana?",
    content:
      "To jedno z najczęściej zadawanych pytań. Odpowiedź brzmi: tak. Zalecam, żeby klient był obecny podczas procesu, a z pewnością na samym początku.  Natomiast jeżeli nasza współpraca będzie trwała dłużej, kiedy już poznam Twoje zwyczaje, styl życia, oczekiwania, wówczas, Twoja obecność nie będzie konieczna.",
  },
  {
    title: "Co muszę przygotować przed wizytą?",
    content:
      "Dobre nastawienie i chęć do działania! Poza tym absolutnie nic nie jest potrzebne. Chcę poznać Twoją przestrzeń dokładnie taką, jaka jest naprawdę. Nie sprzątaj i nie układaj, tylko dlatego, że przyjeżdżam do Twojego domu. Nie będę Cię oceniać! Przebywanie w Twojej codziennej przestrzeni, pozwoli mi na realny ogląd sytuacji i przygotowanie najlepszych rozwiązań dostosowanych do Twoich potrzeb.",
  },
  {
    title: "Czy pracujesz popołudniami, wieczorami lub w weekendy?",
    content: `Tak. Wielu moich klientów pracuje od rana do popołudnia, dlatego nie ma żadnego problemu w&nbsp;umówieniu się na wieczór czy np. na sobotę. Niektórzy klienci preferują wziąć 2-3 dni urlopu, żeby jednorazowo przejść przez cały proces. Pamiętaj proszę jednak, że taki "maraton", może być dla Ciebie wyczerpujący. Dlaczego? Ponieważ przed Tobą wiele decyzji do podjęcia i mimo tego, że wydaje się to błahe, to z doświadczenia wiem, że potrafi być przytłaczające. Jednak nie martw się! Jeżeli będziesz potrzebować odpoczynku, przerwiemy proces i umówimy się na kolejne spotkanie, w dogodnym dla Ciebie terminie. Na sam początek proponuję jedną sesję odgracania danego pomieszczenia, która zazwyczaj trwa 4-5h.`,
  },
];
