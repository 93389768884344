<template>
  <div
    v-for="(item, idx) in items"
    :key="idx"
    class="card-box__item"
    @click="selectAboutItem(idx)"
  >
    <div
      :class="[
        'card-box__item__title',
        { 'card-box__item__title--active': selectedItem === idx },
      ]"
    >
      {{ item.title }}
    </div>
    <Transition name="expand" @enter="enter" @leave="leave">
      <div
        v-if="selectedItem === idx"
        class="card-box__item__desc"
        v-html="item.content"
      ></div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "Cardbox",
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = `calc(${height} + 36px)`;
      });
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
    selectAboutItem(idx) {
      this.selectedItem = this.selectedItem === idx ? null : idx;
    },
  },
};
</script>

<style lang="scss" src="./Cardbox.scss" />
