<template>
  <Header />
  <Main />
  <Footer />
</template>

<script>
import Footer from "./components/Footer/Footer.vue";
import Header from "./components/Header/Header.vue";
import Main from "./components/Main.vue";

export default {
  name: "App",
  components: {
    Footer,
    Header,
    Main,
  },
};
</script>
